import { REPORTS_SERVICES } from "./routes";

export const GLOBAL_SEARCH = "common/global-search/";
export const VEHICLES = "/vehicles/";
export const VEHICLES_ENGINE_ASPIRATIONS = `${VEHICLES}engine-aspirations/`;
export const VEHICLES_ENGINE_ASPIRATIONS_CREATE = `${VEHICLES_ENGINE_ASPIRATIONS}create/`;

export const VEHICLES_ASSIGNMENTS = `${VEHICLES}assignments/`;
export const VEHICLES_ASSIGNMENTS_ID = (id: string) =>
  `${VEHICLES_ASSIGNMENTS}${id}/`;
export const VEHICLES_ASSIGNMENTS_ID_DELETE = (id: string) =>
  `${VEHICLES_ASSIGNMENTS}${id}/delete/`;
export const VEHICLES_ASSIGNMENTS_CREATE = `${VEHICLES_ASSIGNMENTS}create/`;
export const VEHICLES_ASSIGNMENTS_ID_UPDATE = (id: string) =>
  `${VEHICLES_ASSIGNMENTS}${id}/update/`;

export const AUTH = "/accounts/";
export const AUTH_LOGIN = `${AUTH}login/`;
export const AUTH_TOKEN_VERIFY = `${AUTH}token/verify/`;
export const AUTH_ME = `${AUTH}me/`;
export const AUTH_ME_PATCH = `${AUTH}me/`;
export const AUTH_PASSWORD = `${AUTH}password/`;
export const AUTH_PASSWORD_RESET = `${AUTH_PASSWORD}reset/`;
export const AUTH_PASSWORD_CONFIRM = `${AUTH_PASSWORD}confirm/`;
export const AUTH_REGISTRATION = `${AUTH}registration/`;
export const AUTH_RESET_EMAIL_CONFIRMATION = `${AUTH}resend-email-confirmation/`;
export const AUTH_EMAIL_CODE = `${AUTH}email-code/`;

export const BLOCK_ENGINE_BLOCK_TYPES = `${VEHICLES}engine-block-types/`;
export const BLOCK_ENGINE_BLOCK_TYPES_CREATE = `${BLOCK_ENGINE_BLOCK_TYPES}crete/`;

export const VEHICLE_BODY_SUBTYPES = `${VEHICLES}vehicle-body-subtypes/`;
export const VEHICLE_BODY_SUBTYPES_CREATE = `${VEHICLE_BODY_SUBTYPES}create/`;

export const VEHICLE_BODY_TYPES = `${VEHICLES}vehicle-body-types/`;
export const VEHICLE_BODY_TYPES_CREATE = `${VEHICLE_BODY_TYPES}create/`;

export const VEHICLE_WHEELS_TIRES_BRAKE_SYSTEMS = `${VEHICLES}wheels-tires-brake-systems/`;
export const VEHICLE_WHEELS_TIRES_BRAKE_SYSTEMS_CREATE = `${VEHICLE_WHEELS_TIRES_BRAKE_SYSTEMS}create/`;

export const VEHICLES_ENGINE_CAM_TYPES = `${VEHICLES}engine-cam-types/`;
export const VEHICLES_ENGINE_CAM_TYPES_CREATE = `${VEHICLES_ENGINE_CAM_TYPES}create/`;

export const COMPANY = "/company/";
export const COMPANY_CURRENCIES = `${COMPANY}currencies/`;
export const COMPANY_INDUSTRIES = `${COMPANY}industries/`;
export const COMPANY_FLEETSIZES = `${COMPANY}fleetsizes/`;
export const COMPANY_UPDATE = `${COMPANY}update/`;
export const COMPANY_ONBOARDING = `${COMPANY}onboarding/`;

export const COMPANY_DOMAIN = "/company/domain";
export const COMPANY_DOMAIN_CHECK = `${COMPANY_DOMAIN}/check/`;
export const COMPANY_DOMAIN_CREATE = `${COMPANY_DOMAIN}/create/`;
export const COMPANY_DOMAIN_SUGGEST = `${COMPANY_DOMAIN}/suggest/`;

export const DOCUMENTS = "/documents/";
export const DOCUMENTS_ID = (id: string) => `${DOCUMENTS}${id}/`;
export const DOCUMENTS_ID_DELETE = (id: string) => `${DOCUMENTS}${id}/delete/`;
export const DOCUMENTS_ID_PATCH = (id: string) => `${DOCUMENTS}${id}/update/`;
export const DOCUMENTS_CREATE = `${DOCUMENTS}create/`;

export const VEHICLES_WHEELS_TIRES_DRIVE_TYPES = `${VEHICLES}wheels-tires-drive-types/`;
export const VEHICLES_WHEELS_TIRES_DRIVE_TYPES_CREATE = `${VEHICLES_WHEELS_TIRES_DRIVE_TYPES}create/`;

export const ISSUES = "/issues/";
export const ISSUES_FAULTS = `${ISSUES}faults/`;
export const ISSUES_ID_FAULTS = (id: string) => `${ISSUES}${id}faults/`;
export const ISSUES_FAULTS_CODES = `${ISSUES}faults_codes/`;
export const ISSUES_FAULTS_RULES = `${ISSUES}faults_rules/`;
export const ISSUES_FAULTS_RULES_ID = (id: string) =>
  `${ISSUES_FAULTS_RULES}${id}/`;
export const ISSUES_FAULTS_RULES_CREATE = `${ISSUES_FAULTS_RULES}create/`;
export const ISSUES_FAULTS_RULES_ID_UPDATE = (id: string) =>
  `${ISSUES_FAULTS_RULES}${id}/update/`;
export const ISSUES_FAULTS_ID_IGNORE = (id: string) =>
  `${ISSUES_FAULTS}${id}/ignore/`;
export const ISSUES_FAULTS_RULES_ID_DELETE = (id: string) =>
  `${ISSUES}faults_rules/${id}/delete/`;

export const VEHICLES_FUEL_TYPES = `${VEHICLES}vehicle-fuel-types/`;
export const VEHICLES_FUEL_TYPES_CREATE = `${VEHICLES_FUEL_TYPES}create/`;

export const COMMON = "/common/";
export const COMMON_GROUPS = `${COMMON}groups/`;
export const COMMON_GROUPS_CREATE = `${COMMON_GROUPS}create/`;
export const COMMON_GROUPS_DELETE = (id: string) =>
  `${COMMON_GROUPS}${id}/delete/`;

export const VEHICLES_ENGINE_FUEL_INDUCTIONS = `${VEHICLES}engine-fuel-inductions/`;
export const VEHICLES_ENGINE_FUEL_INDUCTIONS_CREATE = `${VEHICLES_ENGINE_FUEL_INDUCTIONS}create/`;

export const COMMENTS = "comments/";
export const ISSUES_ID = (id: string) => `${ISSUES}${id}/`;
export const ISSUES_CREATE = `${ISSUES}create/`;
export const ISSUES_ID_UPDATE = (id: string) => `${ISSUES}${id}/update/`;
export const ISSUES_ID_DELETE = (id: string) => `${ISSUES}${id}/delete/`;
export const ISSUES_ID_ISSUES_TIMELINE = (id: string) =>
  `${ISSUES}${id}issues-timeline/`;
export const ISSUES_ID_REOPEN = (id: string) => `${ISSUES}${id}/reopen/`;
export const ISSUES_ID_RESOLVE_WITH_NOTES = (id: string) =>
  `${ISSUES}${id}/resolve_with_notes/`;
export const ISSUES_ID_FILES = (id: string) => `${ISSUES}${id}/files/`;
export const ISSUES_ID_BULK_ADD_FILES = (id: string) =>
  `${ISSUES}${id}/bulk_add_files/`;
export const ISSUES_ID_BULK_REMOVE_FILES = (id: string) =>
  `${ISSUES}${id}/bulk_remove_files/`;
export const ISSUES_ID_COMMENTS_DELETE = (id: string) =>
  `${ISSUES}${id}/comments/delete/`;
export const ISSUES_ID_COMMENTS_CREATE = `${ISSUES}${COMMENTS}create/`;

export const COMMON_LABELS = `${COMMON}labels/`;
export const COMMON_LABELS_CREATE = `${COMMON_LABELS}create/`;

export const VEHICLE_MAKES = `${VEHICLES}vehicle-makes/`;
export const VEHICLE_MAKES_CREATE = `${VEHICLE_MAKES}create/`;

export const VEHICLE_METER_ENTITY = `${VEHICLES}meter-entity/`;
export const VEHICLE_METER_ENTITY_ID = (id: string) =>
  `${VEHICLE_METER_ENTITY}${id}/`;
export const VEHICLE_METER_ENTITY_DELETE = (id: string) =>
  `${VEHICLE_METER_ENTITY}${id}/delete/`;
export const VEHICLE_METER_ENTITY_CREATE = `${VEHICLE_METER_ENTITY}create/`;
export const VEHICLE_METER_ENTITY_ID_UPDATE = (id: string) =>
  `${VEHICLE_METER_ENTITY}${id}/update/`;

export const VEHICLE_MODELS = `${VEHICLES}vehicle-models/`;
export const VEHICLE_MODELS_CREATE = `${VEHICLE_MODELS}create/`;

export const INVENTORY = "/inventory/";
export const INVENTORY_LOCATION_ID_DELETE = (id: string) =>
  `${INVENTORY}location/${id}/delete/`;
export const INVENTORY_PURCHASE_ORDERS = `${INVENTORY}purchase-orders/`;
export const INVENTORY_PURCHASE_ORDERS_ID = (id: string) =>
  `${INVENTORY_PURCHASE_ORDERS}${id}/`;
export const VEHICLES_MODELS_CREATE = `${INVENTORY_PURCHASE_ORDERS}create/`;
export const INVENTORY_PURCHASE_ORDERS_ID_UPDATE = (id: string) =>
  `${INVENTORY_PURCHASE_ORDERS}${id}/update/`;
export const INVENTORY_PURCHASE_ORDERS_ID_DELETE = (id: string) =>
  `${INVENTORY_PURCHASE_ORDERS}${id}/delete/`;
export const INVENTORY_PURCHASE_ORDERS_ID_DOCUMENT = (id: string) =>
  `${INVENTORY_PURCHASE_ORDERS}${id}/documents/`;
export const INVENTORY_PURCHASE_ORDERS_ID_UPLOAD_DOCUMENTS = (id: string) =>
  `${INVENTORY_PURCHASE_ORDERS}${id}/upload-documents/`;
export const INVENTORY_PURCHASE_ORDERS_ID_RECEIVE = (id: string) =>
  `${INVENTORY_PURCHASE_ORDERS}${id}/receive/`;
export const INVENTORY_PURCHASE_ORDERS_ID_REMOVE_DOCUMENTS = (id: string) =>
  `${INVENTORY_PURCHASE_ORDERS}${id}/remove-documents/`;
export const INVENTORY_PURCHASE_ORDERS_ID_STATUS = (id: string) =>
  `${INVENTORY_PURCHASE_ORDERS}${id}/`;

export const ACCOUNTS = "/accounts/";
export const ACCOUNTS_ROLES = `${ACCOUNTS}roles/`;
export const ACCOUNTS_ROLES_CREATE = `${ACCOUNTS}roles/create/`;
export const ACCOUNTS_ROLES_ID_PERMISSIONS_UPDATE = (id: string) =>
  `${ACCOUNTS_ROLES}${id}/permissions/update`;
export const ACCOUNTS_ROLES_ID_PERMISSIONS_MODULES = (id: string) =>
  `${ACCOUNTS_ROLES}${id}/permissions/modules/`;
export const ACCOUNTS_SYSTEM_MODULES = `${ACCOUNTS}system-modules/`;
export const ACCOUNTS_SYSTEM_PERMISSION = `${ACCOUNTS}permissions/`;

export const SAMSARA = "samsara/";
export const SAMSARA_INTEGRATIONS = `${VEHICLES}${SAMSARA}integrations/`;
export const SAMSARA_DRIVERS = `${VEHICLES}${SAMSARA}drivers/`;
export const SAMSARA_VEHICLES = `${VEHICLES}${SAMSARA}vehicles/`;
export const SAMSARA_SAVE_DRIVERS = `${VEHICLES}${SAMSARA}save-drivers/`;
export const SAMSARA_SAVE_VEHICLES = `${VEHICLES}${SAMSARA}save-vehicles/`;
export const SAMSARA_TOKEN_REGISTRATION = `${VEHICLES}${SAMSARA}token-registration/`;
export const SAMSARA_TOKEN_REMOVAL = `${VEHICLES}${SAMSARA}token-removal/`;
export const SAMSARA_VEHICLE_DIAGNOSTICS = `${VEHICLES}${SAMSARA}vehicle-diagnostics/`;
export const SAMSARA_DEVICES_SYNC = `${VEHICLES}${SAMSARA}sync-vehicles/`;
export const SAMSARA_ID_GEO_POSITION = (id: string) =>
  `${VEHICLES}${SAMSARA}${id}/geo-position/`;
export const SAMSARA_UPDATE_FAULTS = `${VEHICLES}${SAMSARA}update_faults/`;

export const VEHICLES_TELEMATICS_DEVICES = `${VEHICLES}telematics-devices/`;
export const VEHICLES_ID_ARCHIVE_TELEMATICS_DEVICES = (id: string) =>
  `${VEHICLES}${id}/archive-telematics-device`;
export const VEHICLES_ID_UNASSIGN_VEHICLE = (id: string) =>
  `${VEHICLES}${id}/unassign-vehicle/`;
export const VEHICLES_ID_ASSIGN_VEHICLE = (id: string) =>
  `${VEHICLES}${id}/assign-vehicle/`;

export const VEHICLE_TRANSMISSION_TYPES = `${VEHICLES}transmission-types/`;
export const VEHICLE_TRANSMISSION_TYPES_CREATE = `${VEHICLE_TRANSMISSION_TYPES}create/`;

export const VEHICLE_TYPES = `${VEHICLES}vehicle-types/`;
export const VEHICLE_TYPES_CREATE = `${VEHICLE_TYPES}create/`;

export const CONTACTS = "contacts/";
export const ACCOUNTS_CONTACTS = `${ACCOUNTS}${CONTACTS}`;
export const ACCOUNTS_CONTACTS_ID = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/`;
export const ACCOUNTS_CONTACTS_ID_ARCHIVE = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/archive/`;
export const ACCOUNTS_CONTACTS_ID_RESTORE_ARCHIVED = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/restore_archived/`;
export const ACCOUNTS_CONTACTS_ID_DELETE = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/delete/`;
export const ACCOUNTS_CONTACTS_MULTIPLY_ARCHIVE = `${ACCOUNTS}${CONTACTS}bulk/archive/`;
export const ACCOUNTS_CONTACTS_MULTIPLY_UNARCHIVE = `${ACCOUNTS}${CONTACTS}bulk/restore_archived/`;
export const ACCOUNTS_CONTACTS_MULTIPLY_DELETE = `${ACCOUNTS}${CONTACTS}bulk/delete/`;
export const ACCOUNTS_CONTACTS_CREATE = `${ACCOUNTS}${CONTACTS}create/`;
export const ACCOUNTS_CONTACTS_ID_UPDATE = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/update/`;
export const ACCOUNTS_CONTACTS_ID_FILES = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/files/`;
export const ACCOUNTS_CONTACTS_ID_FILES_CREATE = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/files/create/`;
export const ACCOUNTS_CONTACTS_ID_FILES_DELETE = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/files/delete/`;
export const ACCOUNTS_PASSWORD_CONTACTS_SETUP = `${ACCOUNTS}password/contact/setup/`;
export const ACCOUNTS_CONTACTS_ID_RESEND_INVITATION = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/resend_invitation/`;
export const ACCOUNTS_CONTACTS_COMMENTS_CREATE = `${ACCOUNTS}${CONTACTS}${COMMENTS}create/`;
export const ACCOUNTS_CONTACTS_ID_COMMENTS_DELETE = (id: string) =>
  `${ACCOUNTS}${CONTACTS}${id}/${COMMENTS}delete/`;

export const VEHICLES_ID = (id: string) => `${VEHICLES}${id}/`;
export const VEHICLES_CREATE = `${VEHICLES}create/`;
export const VEHICLES_ID_DELETE = (id: string) => `${VEHICLES}${id}/delete/`;
export const VEHICLES_ID_RESTORE_ARCHIVE = (id: string) =>
  `${VEHICLES}${id}/restore_archived/`;
export const VEHICLES_ID_ARCHIVE = (id: string) => `${VEHICLES}${id}/archive/`;
export const VEHICLES_ID_UPDATE = (id: string) => `${VEHICLES}${id}/update/`;
export const VEHICLES_ID_LINKED = (id: string) => `${VEHICLES}${id}/linked/`;
export const VEHICLES_ID_LINK = (id: string) => `${VEHICLES}${id}/link/`;
export const VEHICLES_ID_UNLINK = (id: string) => `${VEHICLES}${id}/unlink/`;
export const VEHICLES_ID_ISSUES = (id: string) => `${VEHICLES}${id}/issues/`;
export const VEHICLES_ID_METER_ENTRIES = (id: string) =>
  `${VEHICLES}${id}/meter-entries/`;
export const VEHICLES_ID_SERVICES = (id: string) =>
  `${VEHICLES}${id}/services/`;
export const VEHICLES_ID_FAULTS = (id: string) => `${VEHICLES}${id}/faults/`;
export const VEHICLES_ID_DOCUMENTS = (id: string) =>
  `${VEHICLES}${id}/documents/`;
export const VEHICLE_LATEST_ODOMETER = (id: string) =>
  `${VEHICLES}${id}/latest-odometer/`;
export const VEHICLES_ID_BULK_ADD_DOCUMENTS = (id: string) =>
  `${VEHICLES}${id}/bulk-add-documents/`;
export const VEHICLES_ID_BULK_REMOVE_DOCUMENTS = (id: string) =>
  `${VEHICLES}${id}/bulk-remove-documents/`;
export const VEHICLES_COMMENTS_CREATE = `${VEHICLES}${COMMENTS}create/`;
export const VEHICLES_ID_COMMENTS_DELETE = (id: string) =>
  `${VEHICLES}${id}/${COMMENTS}delete/`;
export const VEHICLES_ID_ASSIGNED_EQUIPMENTS = (id: string) =>
  `${VEHICLES}${id}/equipments/`;
export const VENDORS = "/vendors/";
export const NEAREST_VENDORS = (vehicle_id: string) =>
  `/vendors/${vehicle_id}/nearest-vendors/`;
export const VENDORS_ID = (id: string) => `${VENDORS}${id}/`;
export const VENDORS_ID_UPDATE = (id: string) => `${VENDORS}${id}/update/`;
export const VENDORS_CREATE = `${VENDORS}create/`;
export const VENDORS_ID_DELETE = (id: string) => `${VENDORS}${id}/delete/`;
export const VENDORS_COMMENTS_CREATE = `${VENDORS}comments/create/`;
export const VENDORS_ID_COMMENTS_DELETE = (id: string) =>
  `${VENDORS}${id}/comments/delete/`;

export const VENDORS_ID_BULK_ADD_FILES = (id: string) =>
  `${VENDORS}${id}/bulk_add_files/`;
export const VENDORS_ID_BULK_REMOVE_FILES = (id: string) =>
  `${VENDORS}${id}/bulk_remove_files/`;
export const VENDORS_ID_FILES = (id: string) => `${VENDORS}${id}/files/`;

export const INVENTORY_LOCATION = `${INVENTORY}location/`;
export const INVENTORY_LOCATION_CREATE = `${INVENTORY_LOCATION}create/`;
export const INVENTORY_LOCATION_ID_UPDATE = (id: string) =>
  `${INVENTORY_LOCATION}${id}/update/`;

export const PARTS = "parts/";
export const INVENTORY_PARTS = `${INVENTORY}${PARTS}`;
export const INVENTORY_PARTS_ID_DELETE = (id: string) =>
  `${INVENTORY}${PARTS}${id}/delete/`;
export const INVENTORY_PARTS_ID_ARCHIVE = (id: string) =>
  `${INVENTORY}${PARTS}${id}/archive/`;
export const INVENTORY_PARTS_ID_UNARCHIVE = (id: string) =>
  `${INVENTORY}${PARTS}${id}/unarchive/`;
export const INVENTORY_PARTS_ID = (id: string) => `${INVENTORY}${PARTS}${id}/`;
export const INVENTORY_PARTS_ID_WORD_ORDER = (id: string) =>
  `${INVENTORY}${PARTS}${id}/work-order-activity/`;
export const INVENTORY_PARTS_ID_PURCHASE_HISTORY = (id: string) =>
  `${INVENTORY}${PARTS}${id}/purchase-history/`;
export const INVENTORY_PARTS_ID_PARTS_LOCATION = (id: string) =>
  `${INVENTORY}${PARTS}${id}/parts_location/`;
export const INVENTORY_PARTS_ARCHIVED = `${INVENTORY}${PARTS}archived/`;
export const INVENTORY_PARTS_CATEGORIES = `${INVENTORY}${PARTS}categories/`;
export const ADD_INVENTORY_PARTS_CATEGORIES = `${INVENTORY_PARTS_CATEGORIES}create/`;
export const INVENTORY_PARTS_MANUFACTURES = `${INVENTORY}${PARTS}manufacturers/`;
export const ADD_INVENTORY_PARTS_MANUFACTURES = `${INVENTORY_PARTS_MANUFACTURES}create/`;
export const INVENTORY_PARTS_METADATA = `${INVENTORY}${PARTS}meta_data/`;
export const INVENTORY_PARTS_ID_METADATA = (id: string) =>
  `${INVENTORY}${PARTS}${id}/meta_data/`;
export const INVENTORY_PARTS_CREATE = `${INVENTORY}${PARTS}create/`;
export const INVENTORY_PARTS_ID_UPDATE = (id: string) =>
  `${INVENTORY}${PARTS}${id}/update/`;
export const INVENTORY_PARTS_DOCUMENTS = `${INVENTORY}${PARTS}documents/`;
export const INVENTORY_PARTS_ID_BULK_ADD_DOCUMENTS = (id: string) =>
  `${INVENTORY}${PARTS}${id}/bulk-add-documents/`;
export const INVENTORY_PARTS_ID_BULK_REMOVE_DOCUMENTS = (id: string) =>
  `${INVENTORY}${PARTS}${id}/bulk-remove-documents/`;

export const INVENTORY_PARTS_MEASUREMENT_UNITS = `${INVENTORY}${PARTS}measurement-units/`;
export const ADD_INVENTORY_PARTS_MEASUREMENT_UNITS = `${INVENTORY_PARTS_MEASUREMENT_UNITS}create/`;

export const INVENTORY_PARTS_COMMENTS_CREATE = `${INVENTORY}${PARTS}${COMMENTS}create/`;

export const INVENTORY_PARTS_ID_COMMENTS_DELETE = (id: string) =>
  `${INVENTORY}${PARTS}${id}/${COMMENTS}delete/`;

export const SERVICE_TASK = `services/service-task/`;
export const SERVICE_TASK_ID = (id: string) => `${SERVICE_TASK}${id}/`;
export const ARCHIVE_SERVICE_TASK_ID = (id: string) =>
  `${SERVICE_TASK}${id}/archive/`;
export const UNARCHIVE_SERVICE_TASK_ID = (id: string) =>
  `${SERVICE_TASK}${id}/unarchive/`;
export const SERVICE_TASK_ID_CREATE = `${SERVICE_TASK}create/`;
export const SERVICE_TASK_ID_UPDATE = (id: string) =>
  `${SERVICE_TASK}${id}/update/`;
export const SERVICE_TASK_ID_DELETE = (id: string) =>
  `${SERVICE_TASK}${id}/delete/`;
export const SERVICE_TASK_SYSTEM_CODES = `${SERVICE_TASK}system-codes/`;
export const SERVICE_TASK_MAINTENANCE_CATEGORIES = `${SERVICE_TASK}maintenance-categories/`;
export const SERVICE_TASK_ASSEMBLY_CODES = `${SERVICE_TASK}assembly-codes/`;
export const SERVICE_TASK_REASON_FOR_REPAIR_CODES = `${SERVICE_TASK}reason-for-repair-codes/`;
export const SERVICE_TASK_SYSTEM_CODES_CREATE = `${SERVICE_TASK_SYSTEM_CODES}create/`;
export const SERVICE_TASK_MAINTENANCE_CATEGORIES_CREATE = `${SERVICE_TASK_MAINTENANCE_CATEGORIES}create/`;
export const SERVICE_TASK_ASSEMBLY_CODES_CREATE = `${SERVICE_TASK_ASSEMBLY_CODES}create/`;
export const SERVICE_TASK_REASON_FOR_REPAIR_CODES_CREATE = `${SERVICE_TASK_REASON_FOR_REPAIR_CODES}create/`;
export const SERVICES = "/services/";
export const SERVICES_ID = (id: string) => `${SERVICES}${id}/`;
export const SERVICES_ID_DELETE = (id: string) => `${SERVICES}${id}/delete/`;
export const SERVICES_ID_ARCHIVE = (id: string) => `${SERVICES}${id}/archive/`;
export const SERVICES_ID_UNARCHIVE = (id: string) =>
  `${SERVICES}${id}/unarchive/`;
export const SERVICES_ID_UPDATE = (id: string) => `${SERVICES}${id}/update/`;
export const SERVICES_CREATE = `${SERVICES}create/`;
export const SERVICES_ID_FILES = (id: string) => `${SERVICES}${id}/documents/`;
export const SERVICES_ID_BULK_ADD_DOCUMENTS = (id: string) =>
  `${SERVICES}${id}/bulk-add-documents/`;
export const SERVICES_ID_BULK_REMOVE_DOCUMENTS = (id: string) =>
  `${SERVICES}${id}/bulk-remove-documents/`;
export const SERVICES_COMMENTS_CREATE = `${SERVICES}${COMMENTS}create/`;
export const SERVICES_COMMENTS_DELETE = (id: string) =>
  `${SERVICES}${id}/${COMMENTS}delete/`;

export const WORK_ORDER = "/services/work-order/";
export const WORK_ORDER_ID = (id: string) => `${WORK_ORDER}${id}/`;
export const WORK_ORDER_SWITCH_TO_OPEN_STATUS_ID = (id: string) =>
  `${WORK_ORDER}${id}/move-to-open/`;
export const WORK_ORDER_SWITCH_TO_PENDING_STATUS_ID = (id: string) =>
  `${WORK_ORDER}${id}/move-to-pending/`;
export const WORK_ORDER_SWITCH_TO_COMPLETE_STATUS_ID = (id: string) =>
  `${WORK_ORDER}${id}/move-to-complete/`;
export const WORK_ORDER_DELETE_ID = (id: string) =>
  `${WORK_ORDER}${id}/delete/`;
export const WORK_ORDER_DELETE_LABOR_ID = (wo_id: string, id: string) =>
  `${WORK_ORDER}${wo_id}/delete-labor/${id}/`;
export const WORK_ORDER_RELATED_SERVICE_TASKS_ID = (id: string) =>
  `${WORK_ORDER}${id}/related_service_tasks/`;
export const START_WORK_ORDER_LABOR_ID = (wo_id: string) =>
  `${WORK_ORDER}${wo_id}/start-labor/`;
export const STOP_WORK_ORDER_LABOR_ID = (wo_id: string, id: string) =>
  `${WORK_ORDER}${wo_id}/stop-labor/${id}/`;

export const ADD_WORK_ORDER_COMMENT = `${WORK_ORDER}comments/create/`;
export const DELETE_WORK_ORDER_COMMENT = (id: string) =>
  `${WORK_ORDER}${id}/comments/delete/`;

export const ADD_WORK_ORDER_FILES = (id: string) =>
  `${WORK_ORDER}${id}/bulk-add-documents/`;

export const REMOVE_WORK_ORDER_FILE = (id: string) =>
  `${WORK_ORDER}${id}/bulk-remove-documents/`;
export const ADD_WORK_ORDER = `${WORK_ORDER}create/`;
export const EDIT_WORK_ORDER = (id: string) => `${WORK_ORDER}${id}/update/`;

export const INSPECTIONS = "/inspections/";

export const INSPECTIONS_ID = (id: string) => `${INSPECTIONS}${id}/`;
export const FAILED_ITEMS = "failed-items/";
export const INSPECTIONS_FAILED_ITEMS = `${INSPECTIONS}${FAILED_ITEMS}`;
export const INSPECTIONS_SUBMIT = `${INSPECTIONS}submit-inspection/`;
export const INSPECTIONS_FORMS = `${INSPECTIONS}forms/`;
export const INSPECTIONS_FORMS_ID = (id: string) =>
  `${INSPECTIONS_FORMS}${id}/`;
export const INSPECTIONS_FORMS_ID_DELETE = (id: string) =>
  `${INSPECTIONS_FORMS}delete/${id}/`;
export const DELETE_INSPECTIONS_FORMS_ID = (id: string) =>
  `${INSPECTIONS_FORMS}${id}/delete/`;
export const UPDATE_INSPECTIONS_FORMS_ID = (id: string) =>
  `${INSPECTIONS_FORMS}update/${id}/`;
export const CREATE_INSPECTIONS_FORMS = `${INSPECTIONS_FORMS}create/`;
export const CREATE_INSPECTIONS_FORMS_ID_ITEMS = (id: string) =>
  `${INSPECTIONS_FORMS}${id}/items/`;
export const GET_INSPECTIONS_FORMS_ID_ITEMS = (id: string) =>
  `${INSPECTIONS_FORMS}${id}/items/`;
export const UPDATE_INSPECTIONS_FORMS_ID_ITEMS = (id: string) =>
  `${INSPECTIONS_FORMS}${id}/items/`;
export const INSPECTIONS_FAILED_ITEMS_ID_ACKNOWLEDGE = (id: string) =>
  `${INSPECTIONS_FAILED_ITEMS}${id}/acknowledge/`;
export const INSPECTIONS_FORMS_ID_ARCHIVE = (id: string) =>
  `${INSPECTIONS_FORMS}${id}/archive/`;
export const INSPECTIONS_FORMS_ID_UNARCHIVE = (id: string) =>
  `${INSPECTIONS_FORMS}${id}/unarchive/`;
export const GET_INSPECTION_FORM_ITEMS = (id: string) =>
  `${INSPECTIONS_FORMS}${id}/items/`;

export const EQUIPMENTS = "/equipments/";
export const EQUIPMENTS_ASSIGNED = `${EQUIPMENTS}assigned/`;
export const EQUIPMENTS_ASSIGNED_VEHICLES = `${EQUIPMENTS_ASSIGNED}vehicles/`;
export const EQUIPMENTS_TYPES = `${EQUIPMENTS}types/`;
export const EQUIPMENTS_TYPES_CREATE = `${EQUIPMENTS_TYPES}create/`;
export const EQUIPMENTS_TYPES_DELETE = (id: string) =>
  `${EQUIPMENTS_TYPES}${id}/delete/`;
export const EQUIPMENTS_ID_BULK_ADD_FILES = (id: string) =>
  `${EQUIPMENTS}${id}/bulk_add_files/`;
export const EQUIPMENTS_ID_BULK_REMOVE_FILES = (id: string) =>
  `${EQUIPMENTS}${id}/bulk_remove_files/`;
export const EQUIPMENTS_VEHICLES = `${EQUIPMENTS}vehicles/`;
export const EQUIPMENTS_USERS = `${EQUIPMENTS}users/`;
export const EQUIPMENTS_ID = (id: string) => `${EQUIPMENTS}${id}/`;
export const EQUIPMENTS_CREATE = `${EQUIPMENTS}create/`;
export const EQUIPMENTS_UPDATE = (id: string) => `${EQUIPMENTS}${id}/update/`;
export const EQUIPMENTS_ID_UNASSIGN = (id: string) =>
  `${EQUIPMENTS}${id}/unassign/`;
export const EQUIPMENTS_ID_ASSIGN = (id: string) =>
  `${EQUIPMENTS}${id}/assign/`;
export const EQUIPMENTS_ID_DELETE = (id: string) =>
  `${EQUIPMENTS}${id}/delete/`;
export const EQUIPMENTS_ID_ASSIGNMENT_DELETE = (id: string) =>
  `${EQUIPMENTS}${id}/assignment/delete/`;
export const EQUIPMENTS_ID_LINKED_VEHICLES = (id: string) =>
  `${EQUIPMENTS}${id}/linked-vehicles/`;
export const EQUIPMENTS_ID_ASSIGNMENTS = (id: string) =>
  `${EQUIPMENTS}${id}/assignments/`;
export const EQUIPMENTS_ID_RENT_HISTORY = (id: string) =>
  `${EQUIPMENTS}${id}/rent-history/`;
export const EQUIPMENTS_RENT_HISTORIES = `${EQUIPMENTS}rent-histories/`;
export const EQUIPMENTS_RENT_HISTORIES_CREATE = `${EQUIPMENTS_RENT_HISTORIES}create/`;
export const EQUIPMENTS_RENT_HISTORIES_ID_DELETE = (id: string) =>
  `${EQUIPMENTS_RENT_HISTORIES}${id}/delete/`;
export const EQUIPMENTS_RENT_HISTORIES_ID_UPDATE = (id: string) =>
  `${EQUIPMENTS_RENT_HISTORIES}${id}/update/`;
export const EQUIPMENTS_RENT_HISTORIES_PAID = `${EQUIPMENTS_RENT_HISTORIES}paid/`;
export const EQUIPMENTS_RENT_HISTORIES_UNPAID = `${EQUIPMENTS_RENT_HISTORIES}unpaid/`;
export const FUEL_HISTORY = "/fuel-entries/";
export const FUEL_HISTORY_STATISTICS = `${FUEL_HISTORY}fuel-statistics/`;
export const FUEL_HISTORY_DELETE = (id: string) =>
  `${FUEL_HISTORY}${id}/delete/`;
export const FUEL_HISTORY_MULTIPLE_DELETE = `${FUEL_HISTORY}delete/bulk/`;
export const FUEL_HISTORY_ID = (id: string) => `${FUEL_HISTORY}${id}/`;
export const FUEL_HISTORY_DELETE_FILES = (id: string) =>
  `${FUEL_HISTORY}${id}/bulk_remove_files/`;
export const FUEL_HISTORY_UPDATE = (id: string) =>
  `${FUEL_HISTORY}${id}/update/`;
export const FUEL_HISTORY_CREATE = `${FUEL_HISTORY}create/`;
export const FUEL_HISTORY_CREATE_COMMENT = `${FUEL_HISTORY}comments/create/`;
export const FUEL_HISTORY_DELETE_COMMENT = (id: string) =>
  `${FUEL_HISTORY}${id}/comments/delete/`;

export const VIDEOS = "media-center/training-videos/";
export const VIDEOS_SECTIONS = `${VIDEOS}sections/`;
export const VIDEOS_SECTIONS_CATEGORIES = `${VIDEOS_SECTIONS}categories/`;

export const REMINDERS = "reminders/";
export const REMINDERS_CONTACTS = `${REMINDERS}contact_renewal/`;
export const REMINDERS_CONTACTS_ID = (id: string) =>
  `${REMINDERS_CONTACTS}${id}/`;
export const REMINDERS_CONTACTS_CREATE = `${REMINDERS_CONTACTS}create/`;
export const REMINDERS_CONTACTS_UPDATE_ID = (id: string) =>
  `${REMINDERS_CONTACTS}${id}/update/`;
export const REMINDERS_CONTACTS_DELETE_ID = (id: string) =>
  `${REMINDERS_CONTACTS}${id}/delete/`;
export const REMINDERS_CONTACTS_MULTIPLE_DELETE = `${REMINDERS_CONTACTS}delete-multiple/`;
export const REMINDERS_CONTACTS_TYPE = `${REMINDERS}contact_renewal_type/`;
export const REMINDERS_CONTACTS_RENEWAL_TYPE_CREATE = `${REMINDERS_CONTACTS_TYPE}create/`;
export const REMINDERS_VEHICLES = `${REMINDERS}vehicle_renewal/`;
export const REMINDERS_VEHICLES_TYPE = `${REMINDERS}vehicle_renewal_type/`;
export const REMINDERS_VEHICLES_ID = (id: string) =>
  `${REMINDERS_VEHICLES}${id}/`;
export const REMINDERS_VEHICLES_CREATE = `${REMINDERS_VEHICLES}create/`;
export const REMINDERS_VEHICLES_UPDATE_ID = (id: string) =>
  `${REMINDERS_VEHICLES}${id}/update/`;
export const REMINDERS_VEHICLES_DELETE_ID = (id: string) =>
  `${REMINDERS_VEHICLES}${id}/delete/`;
export const REMINDERS_SERVICES = `${REMINDERS}service_reminders/`;
export const REMINDERS_SERVICES_ID = (id: string) =>
  `${REMINDERS_SERVICES}${id}/`;
export const REMINDERS_SERVICES_COMMENTS_CREATE = `${REMINDERS_SERVICES}comments/create/`;
export const REMINDERS_SERVICES_COMMENTS_DELETE = (id: string) =>
  `${REMINDERS_SERVICES}comments/${id}/delete/`;
export const REMINDERS_SERVICES_ADD_DOCUMENT = (id: string) =>
  `${REMINDERS_SERVICES}${id}/bulk-add-documents/`;
export const REMINDERS_SERVICES_DELETE_DOCUMENT = (id: string) =>
  `${REMINDERS_SERVICES}${id}/bulk-remove-documents/`;
export const REMINDERS_SERVICES_DELETE = (id: string) =>
  `${REMINDERS_SERVICES}${id}/delete/`;
export const REMINDERS_SERVICES_DELETE_MULTIPLE = `${REMINDERS_SERVICES}bulk-delete/`; //comment
export const REMINDERS_VEHICLES_DELETE_MULTIPLE = `${REMINDERS_VEHICLES}delete-multiple/`;
export const REMINDERS_VEHICLE_RENEWAL_TYPE = `${REMINDERS}vehicle_renewal_type/`;
export const REMINDERS_VEHICLE_RENEWAL_TYPE_CREATE = `${REMINDERS_VEHICLE_RENEWAL_TYPE}create/`;
export const REMINDERS_SERVICES_CREATE = `${REMINDERS_SERVICES}create/`;
export const REMINDERS_SERVICES_CREATE_BULK_VEHICLE = `${REMINDERS_SERVICES}bulk-create/`;

export const REMINDERS_SERVICES_RESOLVE_BY_SERVICE_TASK = (id: string) =>
  `${REMINDERS_SERVICES}${id}/resolve/`;
export const REMINDERS_SERVICES_UPDATE = (id: string) =>
  `${REMINDERS_SERVICES}${id}/update/`;
export const REMINDERS_SERVICES_SNOOZE = (id: string) =>
  `${REMINDERS_SERVICES}${id}/snooze/`;
export const REMINDERS_SERVICES_RESUME = (id: string) =>
  `${REMINDERS_SERVICES}${id}/resume/`;
export const REMINDERS_SERVICES_RESTART = (id: string) =>
  `${REMINDERS_SERVICES}${id}/restart/`;
export const REPORTS = "/reports";
export const REPORTS_VEHICLE = `${REPORTS}/vehicles`;
export const REPORTS_VEHICLE_LIST_OF_UNITS = `${REPORTS_VEHICLE}/list-of-units/`;
export const REPORTS_VEHICLE_STATUS_CHANGES = `${REPORTS_VEHICLE}/status-changes/`;
export const REPORTS_SERVICE_REMINDER_COMPLIANCE = `${REPORTS}${SERVICES}service-reminder-compliance/`;
export const REPORTS_PARTS_BY_VEHICLE = `${REPORTS}/${PARTS}parts-by-vehicle/`;
export const REPORTS_SERVICE_ENTRIES_SUMMARY =
  REPORTS_SERVICES + "/service-entries-summary/";
export const REPORTS_VEHICLE_STATUS_SUMMARY = `${REPORTS_VEHICLE}/status-summary/`;
export const REPORTS_COST_COMPARISON_BY_YEAR_IN_SERVICE = `${REPORTS_VEHICLE}/cost-comparison-in-service/`;
export const REPORTS_VEHICLE_ASSIGNMENTS = `${REPORTS}/vehicle-assignments`;
export const REPORTS_VEHICLE_ASSIGNMENT_SUMMARY = `${REPORTS_VEHICLE_ASSIGNMENTS}/assignments-summary/`;
export const REPORTS_INSPECTIONS_SUBMISSION_LIST = `${REPORTS}${INSPECTIONS}inspections-list/`;
export const REPORTS_OPERATING_COST_SUMMARY = `${REPORTS_VEHICLE}/operating-costs-summary/`;
export const REPORTS_PURCHASE_ORDERS_LIST = `${REPORTS}/parts/purchase-orders-list/`;

// Dashboard
export const DASHBOARD = "/dashboard";
export const DASHBOARD_VEHICLE_STATUS = `${DASHBOARD}/vehicle-status/`;
export const DASHBOARD_ISSUES = `${DASHBOARD}/issues/`;
export const DASHBOARD_ISSUES_STATS = `${DASHBOARD_ISSUES}stats/`;
export const DASHBOARD_ISSUES_CALENDAR = `${DASHBOARD_ISSUES}calendar/`;
export const DASHBOARD_INSPECTIONS_SUBMISSION =
  DASHBOARD + "/inspections/submissions";
export const DASHBOARD_INSPECTION_SUBMISSION_WEEKLY =
  DASHBOARD_INSPECTIONS_SUBMISSION + "/weekly-comparison/";
export const DASHBOARD_INSPECTION_SUBMISSION_WEEKLY_OUTCOMES =
  DASHBOARD_INSPECTIONS_SUBMISSION + "/weekly-outcomes/";
export const DASHBOARD_LOCATION = `${DASHBOARD}/location/`;

export const DASHBOARD_ANALYTICS_COMPLETE = DASHBOARD + "/analytics/complete/";
export const DASHBOARD_VEHICLE_ASSIGNMENTS = `${DASHBOARD}/vehicle-assignments/`;
export const DASHBOARD_REMINDERS = `${DASHBOARD}/reminders/`;
export const DASHBOARD_WORK_ORDER = `${DASHBOARD}/work-order/`;
export const DASHBOARD_RECENT_COMMENTS = `${DASHBOARD}/recent-comments/`;
export const DASHBOARD_VEHICLE_ASSIGNMENT_ID = (id: string) =>
  `${DASHBOARD}/vehicle-assignments/${id}/`;

export const ISSUES_CHART_ANALYTICS = `${DASHBOARD_ISSUES}widget-list/`;

// import

export const DATA_MIGRATION = `/data_migration/`;
export const DATA_MIGRATION_IMPORT_BULK = `${DATA_MIGRATION}import/bulk/`;
export const DATA_MIGRATION_IMPORT_DATA = `${DATA_MIGRATION}import/data/`;
export const DATA_MIGRATION_EXPORT_DATA = `${DATA_MIGRATION}export/data/`;
export const DATA_MIGRATION_SECTIONS = `${DATA_MIGRATION}sections/`;
export const DATA_MIGRATION_SECTIONS_TEMPLATES = `${DATA_MIGRATION_SECTIONS}templates/`;

// invoice
export const BILLING = `/billing`;
export const BILLING_INVOICES_LIST = `${BILLING}/invoices/`;
export const BILLING_INFO = `${BILLING}/info/`;
export const BILLING_CURRENT_INVOICE = `${BILLING}/current-invoice/`;

// AI
export const AI = "ai";
export const AI_INSPECTION_ANALYZE = AI + "/inspection/analyze/";

// payment type
export const SERVICES_PAYMENT_TYPE = `${SERVICES}payment-type/`;
export const SERVICES_PAYMENT_TYPE_CREATE = `${SERVICES_PAYMENT_TYPE}create/`;
// expense
export const EXPENSE = `${VEHICLES}expenses/`;
export const EXPENSE_DELETE = (id: string) => `${EXPENSE}${id}/delete/`;
export const VEHICLES_WITH_EXPENSES = `${VEHICLES}with-expenses/`;
