import {
  AssignmentsIcon,
  CheckCircleIcon,
  DocumentIcon,
  FuelIcon,
  MeterHistoryIcon,
  Parts,
  SettingsIcon,
  TelematicsIcon,
  UsersIcon,
  VendorIcon,
  VihicleIcon,
  WarningIcon,
  WrenchIcon,
  EquipmentIcon,
  VideoIcon,
  AlarmIcon,
  ReportIcon,
  DashboardIcon,
  GeneralIcon,
  ServiceIconSection,
  InventoryManagementIcon,
  ExpenseIcon,
} from "Assets/index";
import {
  ASSIGNMENTS,
  FAULTS,
  ISSUE,
  METER_HISTORY,
  PAPERS,
  PART,
  PURCHASE_ORDER,
  SETTINGS_ROLE,
  SAMSARA_DEVICES,
  UNITS,
  USERS,
  VENDORS,
  SERVICE_HISTORY,
  SERVICE_TASKS,
  WORK_ORDERS,
  INSPECTIONS_FORMS,
  INSPECTIONS_HISTORY,
  INSPECTIONS_ITEM_FAILURES,
  FUEL_HISTORY,
  EQUIPMENTS,
  TRAINING_VIDEOS,
  REMINDERS_SERVICES,
  REMINDERS_VEHICLES,
  REMINDERS_CONTACTS,
  REPORTS,
  DASHBOARD,
  CONTACTS,
  EXPENSE,
} from "./routes";
import { TRAINING_VIDEO_TITLE } from "Containers/Training/TrainingVideoList/constants";

export const generalData = [
  {
    title: "General management",
    icon: GeneralIcon,
    subMenu: [
      {
        icon: DashboardIcon,
        isDropdown: false,
        title: "Dashboard",
        withNotificaion: true,
        notifcationsCount: 0,
        url: DASHBOARD,
        subMenu: [],
      },
      {
        icon: VihicleIcon,
        isDropdown: false,
        title: "Units",
        withNotificaion: true,
        notifcationsCount: 0,
        url: UNITS,
        subMenu: [],
      },
      {
        icon: UsersIcon,
        title: "Users",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: USERS,
        subMenu: [
          {
            title: "Users",
            url: USERS,
            icon: null,
          },
          {
            title: "Contact",
            url: CONTACTS,
            icon: null,
          },
        ],
      },
      {
        icon: TelematicsIcon,
        title: "Samsara Devices",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: SAMSARA_DEVICES,
        subMenu: [],
      },
      {
        icon: AssignmentsIcon,
        title: "Assignment",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: ASSIGNMENTS,
        subMenu: [],
      },
      {
        icon: MeterHistoryIcon,
        title: "Meter History",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: METER_HISTORY,
        subMenu: [],
      },
      {
        icon: FuelIcon,
        title: "Fuel History",
        isDropdown: true,
        withNotificaion: false,
        notifcationsCount: 0,
        url: FUEL_HISTORY,
        subMenu: [],
      },
      {
        icon: ReportIcon,
        title: "Reports",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: REPORTS,
        subMenu: [],
      },
      {
        icon: ExpenseIcon,
        title: "Expense",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: EXPENSE,
        subMenu: [],
      },
      {
        icon: VideoIcon,
        title: TRAINING_VIDEO_TITLE,
        isDropdown: true,
        withNotificaion: false,
        notifcationsCount: 0,
        url: TRAINING_VIDEOS,
        subMenu: [],
      },
      {
        icon: DocumentIcon,
        title: "Papers",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: PAPERS,
        subMenu: [],
      },
      // TODO: remove settings from sidebar once it moved
      {
        icon: SettingsIcon,
        title: "Settings",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: SETTINGS_ROLE,
        subMenu: [],
      },
    ],
  },
  {
    title: "Service management",
    icon: ServiceIconSection,
    subMenu: [
      {
        icon: CheckCircleIcon,
        title: "Inspections",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: INSPECTIONS_HISTORY,
        subMenu: [
          {
            title: "History",
            url: INSPECTIONS_HISTORY,
            icon: null,
          },
          {
            title: "Forms",
            url: INSPECTIONS_FORMS,
            icon: null,
          },
          {
            title: "Item Failures",
            url: INSPECTIONS_ITEM_FAILURES,
            icon: null,
          },
        ],
      },
      {
        icon: WarningIcon,
        title: "Issue",
        isDropdown: false,
        withNotificaion: true,
        notifcationsCount: 0,
        url: ISSUE,
        subMenu: [
          {
            title: "Issue",
            url: ISSUE,
            icon: null,
          },
          {
            title: "Faults",
            url: FAULTS,
            icon: null,
          },
        ],
      },
      {
        icon: WrenchIcon,
        title: "Service",
        isDropdown: false,
        withNotificaion: true,
        notifcationsCount: 0,
        url: SERVICE_HISTORY,
        subMenu: [
          {
            title: "Service history",
            url: SERVICE_HISTORY,
            icon: null,
          },
          {
            title: "Service tasks",
            url: SERVICE_TASKS,
            icon: null,
          },
          {
            title: "Work orders",
            url: WORK_ORDERS,
            icon: null,
          },
        ],
      },
      {
        icon: AlarmIcon,
        title: "Reminder",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: REMINDERS_CONTACTS,
        subMenu: [
          {
            title: "Contact Renewal",
            url: REMINDERS_CONTACTS,
            icon: AlarmIcon,
          },
          {
            title: "Vehicle Renewal",
            url: REMINDERS_VEHICLES,
            icon: null,
          },
          {
            title: "Service Reminders",
            url: REMINDERS_SERVICES,
            icon: null,
          },
        ],
      },
    ],
  },
  {
    title: "Inventory management",
    icon: InventoryManagementIcon,
    subMenu: [
      {
        icon: EquipmentIcon,
        isDropdown: false,
        title: "Equipment",
        withNotificaion: false,
        notifcationsCount: 0,
        url: EQUIPMENTS,
        subMenu: [],
      },
      {
        icon: VendorIcon,
        title: "Vendors",
        isDropdown: false,
        withNotificaion: false,
        notifcationsCount: 0,
        url: VENDORS,
        subMenu: [],
      },
      {
        icon: Parts,
        title: "Part",
        isDropdown: true,
        withNotificaion: false,
        notifcationsCount: 0,
        url: PART,
        subMenu: [
          {
            title: "Parts",
            url: PART,
            icon: Parts,
          },
          {
            title: "Purchase Orders",
            url: PURCHASE_ORDER,
            icon: null,
          },
        ],
      },
    ],
  },
];
