import { FC } from "react";

import {
  ASSIGNMENTS,
  CONTACT_SETUP_PASSWORD,
  CREATE_UNIT_ANY,
  EQUIPMENTS,
  EQUIPMENTS_CREATE,
  EQUIPMENTS_UPDATE,
  FAULTS,
  FOR_BIDDEN,
  FUEL_HISTORY,
  FUEL_HISTORY_CREATE,
  FUEL_HISTORY_EDIT,
  HOME,
  INSPECTIONS,
  INSPECTIONS_FORMS,
  INSPECTIONS_FORMS_ADD,
  INSPECTIONS_FORMS_EDIT,
  INSPECTIONS_FORMS_ITEMS,
  INSPECTIONS_FORMS_ITEMS_EDIT,
  INSPECTIONS_HISTORY,
  INSPECTIONS_ITEM_FAILURES,
  ISSUE,
  ISSUES,
  ISSUES_ADD,
  ISSUES_UPDATE,
  LAYOUT,
  LOGIN,
  METER_HISTORY,
  NOT_FOUND,
  ONBOARDING_FORM,
  PAPERS,
  PART,
  PARTS,
  PARTS_CREATE,
  PARTS_EDIT,
  PURCHASE_ORDER,
  PURCHASE_ORDERS,
  PURCHASE_ORDERS_CREATE,
  PURCHASE_ORDERS_EDIT,
  REGISTER,
  REMINDERS_CONTACTS,
  REMINDERS_VEHICLES,
  RESET_PASSWORD,
  RESET_PASSWORD_EMAIL,
  SAMSARA_DEVICES,
  SAMSARA_DEVICES_SAMSARA_INTEGRATION,
  SAMSARA_DEVICES_SAMSARA_INTEG_FINISH,
  SAMSARA_DEVICES_SAMSARA_INTEG_INFO,
  SAMSARA_DEVICES_SAMSARA_INTEG_REGISTR,
  SAMSARA_DEVICES_SAMSARA_INTEG_USERS,
  SAMSARA_DEVICES_SAMSARA_INTEG_VEHICLE,
  SERVICE_HISTORY,
  SERVICE_HISTORY_CREATE,
  SERVICE_HISTORY_UPDATE,
  SERVICE_TASKS,
  SERVICE_TASKS_CREATE,
  SERVICE_TASKS_UPDATE,
  SETTINGS_GENERAL_SETTING,
  SETTINGS_GENERAL_SETTING_EDIT,
  SETTINGS_USER_PROFILE,
  SETTINGS_USER_PROFILE_EDIT,
  SUBDOMAIN_CREATE,
  TRAINING_VIDEOS,
  UNIT,
  UNITS,
  UNITS_UPDATE,
  USER,
  USERS,
  USERS_CREATE,
  USERS_UPDATE,
  VENDOR,
  VENDORS,
  VENDOR_ADD,
  VENDOR_EDIT,
  VERIFICATION,
  WORK_ORDERS,
  WORK_ORDERS_ADD,
  WORK_ORDERS_UPDATE,
  REMINDERS_CONTACTS_ADD,
  REMINDERS_VEHICLES_EDIT,
  REMINDERS_VEHICLES_CREATE,
  REMINDERS_SERVICES_CREATE,
  REMINDERS_SERVICES,
  REMINDERS_SERVICES_ID,
  REPORTS,
  REMINDERS_CONTACTS_UPDATE_ID,
  REMINDERS_SERVICES_UPDATE,
  REPORTS_STATUS_CHANGES,
  REPORTS_LIST_OF_UNITS,
  REPORTS_STATUS_SUMMARY,
  DASHBOARD,
  REPORTS_VEHICLES_RENEWAL_REMINDERS,
  REPORTS_VEHICLE_ASSIGNMENTS_LOG,
  REPORTS_VEHICLE_ASSIGNMENTS_SUMMARY,
  REPORTS_INSPECTIONS_SUBMISSION_SUMMARY,
  CONTACTS,
  REPORTS_INSPECTIONS_SUBMISSION_LIST,
  REPORTS_INSPECTIONS_FAILED_INSPECTIONS,
  REPORTS_VEHICLE_DETAILS,
  REPORTS_OPERATING_COST_SUMMARY,
  REPORTS_ISSUES_LIST,
  REPORTS_SERVICES_REMINDERS,
  REPORTS_WORK_ORDERS_LIST,
  REPORTS_SERVICE_REMINDER_COMPLIANCE,
  REPORTS_ISSUES_FAULTS_SUMMARY,
  REPORTS_WORK_ORDERS_BY_VEHICLE,
  REPORTS_USERS_RENEWAL_REMINDERS,
  REPORTS_WORK_ORDERS_LABOR_LIST,
  REPORTS_PARTS_BY_LOCATION,
  REPORTS_USERS_LIST,
  REPORTS_PART_BY_VEHICLE,
  REPORTS_VEHICLE_DETAILS_ID,
  REPORTS_SERVICES_WITHOUT_SERVICE,
  REPORTS_SERVICE_HISTORY_SUMMARY,
  REPORTS_PURCHASE_ORDERS_LIST,
  REPORTS_PARTS_ACTIVITY,
  REPORTS_SERVICE_HISTORY_BY_VEHICLE,
  REPORTS_COST_COMPARISON_BY_YEAR,
  IMPORT_FILES,
  SETTINGS,
  REPORTS_FUEL_ENTRIES_BY_VEHICLE,
  REPORTS_FUEL_SUMMARY,
  REPORTS_FUEL_SUMMARY_BY_LOCATION,
  SETTINGS_GENERAL_SETTING_BILLING,
  EXPENSE,
  EXPENSE_EDIT,
  EXPENSE_CREATE,
} from "Shared/Constants/routes";

import * as pages from "../Pages/index";

export interface RouteData {
  permissions: ReadonlyArray<any>;
  page: FC;
}

export const routeMap = new Map<string, RouteData>()
  .set(REGISTER, {
    page: pages.Register,
    permissions: [],
  })
  .set(LOGIN, {
    page: pages.Login,
    permissions: [],
  })
  .set(RESET_PASSWORD_EMAIL, {
    page: pages.ForgotPassword1,
    permissions: [],
  })
  .set(`${RESET_PASSWORD}/:token`, {
    page: pages.ForgotPassword2,
    permissions: [],
  })
  .set(`${VERIFICATION}/:token`, {
    page: pages.VerificationPage,
    permissions: [],
  })
  .set(ONBOARDING_FORM, {
    page: pages.OnboardingFormPage,
    permissions: [],
  })

  .set(LAYOUT, {
    permissions: [],
    page: pages.Home,
  })
  .set(HOME, {
    permissions: [],
    page: pages.Home,
  })
  .set(UNITS, {
    permissions: [],
    page: pages.VehicleList,
  })
  .set(`${UNITS_UPDATE}/:id/*`, {
    page: pages.EditVehicle,
    permissions: [],
  })
  .set(`${UNIT}/:id`, {
    page: pages.VehicleDetailPage,
    permissions: [],
  })
  .set(CREATE_UNIT_ANY, {
    page: pages.AddVehicle,
    permissions: [],
  })

  .set(ASSIGNMENTS, {
    page: pages.AssignmentsPage,
    permissions: [],
  })
  .set(`${ASSIGNMENTS}/:id`, {
    page: pages.AssignmentsPage,
    permissions: [],
  })

  .set(SAMSARA_DEVICES, {
    page: pages.TelematicDeviceListPage,
    permissions: [],
  })

  .set(NOT_FOUND, {
    page: pages.Home,
    permissions: [],
  })
  .set(FOR_BIDDEN, {
    page: pages.Home,
    permissions: [],
  })
  .set(SETTINGS, {
    page: pages.SettingsDashboard,
    permissions: [],
  })
  .set(USERS, {
    page: pages.UserDashboardPage,
    permissions: [],
  })
  .set(USERS_CREATE, {
    page: pages.AddUser,
    permissions: [],
  })
  .set(`${USER}/:id`, {
    page: pages.UserDetailsPage,
    permissions: [],
  })
  .set(`${CONTACTS}`, {
    page: pages.ContactsDashboardPage,
    permissions: [],
  })
  .set(`${CONTACT_SETUP_PASSWORD}/:token`, {
    page: pages.SetupUserPasswordPage,
    permissions: [],
  })
  .set(`${USERS_UPDATE}/:id`, {
    page: pages.EditUser,
    permissions: [],
  })
  .set(SAMSARA_DEVICES_SAMSARA_INTEGRATION, {
    page: pages.SamsaraIntegrationPage,
    permissions: [],
  })
  .set(SAMSARA_DEVICES_SAMSARA_INTEG_REGISTR, {
    page: pages.SamsaraRegistrationPage,
    permissions: [],
  })
  .set(SAMSARA_DEVICES_SAMSARA_INTEG_VEHICLE, {
    page: pages.SamsaraVehiclePage,
    permissions: [],
  })
  .set(SAMSARA_DEVICES_SAMSARA_INTEG_USERS, {
    page: pages.SamsaraUsersPage,
    permissions: [],
  })
  .set(SAMSARA_DEVICES_SAMSARA_INTEG_INFO, {
    page: pages.SamsaraInfoPage,
    permissions: [],
  })
  .set(SAMSARA_DEVICES_SAMSARA_INTEG_FINISH, {
    page: pages.SamsaraFinishPage,
    permissions: [],
  })
  .set(VENDORS, {
    page: pages.Vendor,
    permissions: [],
  })
  .set(VENDOR_ADD, {
    page: pages.AddVendors,
    permissions: [],
  })
  .set(`${VENDOR_EDIT}/:id`, {
    page: pages.EditVendor,
    permissions: [],
  })
  .set(`${VENDOR}/:id/`, {
    page: pages.VendorDetail,
    permissions: [],
  })

  .set(FAULTS, {
    page: pages.FaultsList,
    permissions: [],
  })
  .set(ISSUES, {
    page: pages.IssueDashboardPage,
    permissions: [],
  })
  .set(`${ISSUE}/:id`, {
    page: pages.IssueDetailPage,
    permissions: [],
  })
  .set(`${ISSUES_UPDATE}/:id`, {
    page: pages.IssueEditPage,
    permissions: [],
  })
  .set(ISSUES_ADD, {
    page: pages.IssueAddPage,
    permissions: [],
  })
  .set(`${ISSUES_ADD}/:id`, {
    page: pages.IssueAddPage,
    permissions: [],
  })
  .set(METER_HISTORY, {
    page: pages.MeterHistoryListPage,
    permissions: [],
  })
  .set(`${METER_HISTORY}/:id`, {
    page: pages.MeterHistoryListPage,
    permissions: [],
  })
  .set(SETTINGS_GENERAL_SETTING_EDIT, {
    page: pages.GeneralSettingsEditLayout,
    permissions: [],
  })
  .set(SETTINGS_GENERAL_SETTING_BILLING, {
    page: pages.GeneralSettingBilling,
    permissions: [],
  })
  .set(SETTINGS_GENERAL_SETTING, {
    page: pages.GeneralSetting,
    permissions: [],
  })
  .set(SETTINGS_USER_PROFILE, {
    page: pages.SettingUserProfile,
    permissions: [],
  })
  .set(SETTINGS_USER_PROFILE_EDIT, {
    page: pages.EditUserProfile,
    permissions: [],
  })

  .set(PURCHASE_ORDERS, {
    page: pages.PurchaseOrder,
    permissions: [],
  })
  .set(PURCHASE_ORDERS_CREATE, {
    page: pages.AddPurchaseOrder,
    permissions: [],
  })

  .set(`${PURCHASE_ORDERS_EDIT}/:id`, {
    page: pages.EditPurchaseOrder,
    permissions: [],
  })
  .set(`${PURCHASE_ORDER}/:id`, {
    page: pages.PurchaseOrderDetailPage,
    permissions: [],
  })
  .set(PARTS, {
    page: pages.PartDashboard,
    permissions: [],
  })
  .set(PARTS_CREATE, {
    page: pages.AddPart,
    permissions: [],
  })
  .set(`${PARTS_EDIT}/:id`, {
    page: pages.EditPart,
    permissions: [],
  })
  .set(`${PART}/:id`, {
    page: pages.PartDetail,
    permissions: [],
  })
  .set(PAPERS, {
    page: pages.Papers,
    permissions: [],
  })
  .set(SERVICE_HISTORY, {
    page: pages.ServiceHistoryListPage,
    permissions: [],
  })
  .set(SERVICE_HISTORY_CREATE, {
    page: pages.AddServiceHistorys,
    permissions: [],
  })
  .set(`${SERVICE_HISTORY_CREATE}/:id`, {
    page: pages.AddServiceHistorys,
    permissions: [],
  })
  .set(`${SERVICE_HISTORY_UPDATE}/:id`, {
    page: pages.EditsServiceHistory,
    permissions: [],
  })
  .set(`${SERVICE_HISTORY}/:id`, {
    page: pages.ServiceHistoryDetails,
    permissions: [],
  })
  .set(SERVICE_TASKS, {
    page: pages.ServiceTaskListPage,
    permissions: [],
  })
  .set(SERVICE_TASKS_CREATE, {
    page: pages.ServiceTaskAddPage,
    permissions: [],
  })
  .set(`${SERVICE_TASKS_UPDATE}:id`, {
    page: pages.ServiceTaskEditPage,
    permissions: [],
  })
  .set(WORK_ORDERS, {
    page: pages.WorkOrderListPage,
    permissions: [],
  })
  .set(`${WORK_ORDERS}/:id`, {
    page: pages.WorkOrderDetail,
    permissions: [],
  })
  .set(`${WORK_ORDERS_UPDATE}/:id`, {
    page: pages.WorkOrderEditPage,
    permissions: [],
  })
  .set(WORK_ORDERS_ADD, {
    page: pages.WorkOrderAddPage,
    permissions: [],
  })
  .set(`${WORK_ORDERS_ADD}/:id`, {
    page: pages.WorkOrderAddPage,
    permissions: [],
  })
  .set(INSPECTIONS_HISTORY, {
    page: pages.InspectionHistoryList,
    permissions: [],
  })
  .set(INSPECTIONS_FORMS_ITEMS(":id"), {
    page: pages.InspectionFormsItems,
    permissions: [],
  })
  .set(INSPECTIONS_FORMS_ITEMS_EDIT(":id"), {
    page: pages.InspectionFormsItemsEditItemPage,
    permissions: [],
  })
  .set(`${INSPECTIONS_FORMS}/:id`, {
    page: pages.InspectionFormDetailPage,
    permissions: [],
  })
  .set(`${INSPECTIONS_FORMS_EDIT}/:id`, {
    page: pages.InspectionFormsEdit,
    permissions: [],
  })
  .set(INSPECTIONS_FORMS_ADD, {
    page: pages.InspectionFormsCreate,
    permissions: [],
  })
  .set(`${INSPECTIONS_HISTORY}/:id`, {
    page: pages.InspectionDetails,
    permissions: [],
  })
  .set(`${INSPECTIONS}/choose-vehicle/:id`, {
    page: pages.InspectionVehicleChoose,
    permissions: [],
  })
  .set(`${INSPECTIONS}/start-inspection/:id`, {
    page: pages.StartInspection,
    permissions: [],
  })
  .set(INSPECTIONS_FORMS, {
    page: pages.InspectionFormsList,
    permissions: [],
  })
  .set(INSPECTIONS_ITEM_FAILURES, {
    page: pages.InspectionItemFailuresList,
    permissions: [],
  })

  .set(EQUIPMENTS, {
    page: pages.EquipmentDashboardPage,
    permissions: [],
  })
  .set(`${EQUIPMENTS}/:id`, {
    page: pages.EquipmentDetailsPage,
    permissions: [],
  })
  .set(EQUIPMENTS_CREATE, {
    page: pages.CreateEquipment,
    permissions: [],
  })
  .set(`${EQUIPMENTS_UPDATE}/:id`, {
    page: pages.UpdateEquipment,
    permissions: [],
  })
  .set(`${SUBDOMAIN_CREATE}`, {
    page: pages.CreateSubdomen,
    permissions: [],
  })
  .set(`${FUEL_HISTORY}`, {
    page: pages.FuelHistoryList,
    permissions: [],
  })
  .set(`${FUEL_HISTORY_CREATE}`, {
    page: pages.AddFuelHistoryPage,
    permissions: [],
  })
  .set(`${FUEL_HISTORY_EDIT}/:id`, {
    page: pages.EditFuelHistoryPage,
    permissions: [],
  })
  .set(`${FUEL_HISTORY}/:id`, {
    page: pages.FuelHistoryDetail,
    permissions: [],
  })
  .set(`${TRAINING_VIDEOS}`, {
    page: pages.TrainingVideoListPage,
    permissions: [],
  })
  .set(REMINDERS_CONTACTS, {
    page: pages.ReminderContactsListPage,
    permissions: [],
  })

  .set(REMINDERS_CONTACTS_ADD, {
    page: pages.ReminderContactAddPage,
    permissions: [],
  })
  .set(REMINDERS_CONTACTS_UPDATE_ID(":id"), {
    page: pages.ReminderContactEditPage,
    permissions: [],
  })

  .set(REMINDERS_VEHICLES, {
    page: pages.ReminderVehiclesListPage,
    permissions: [],
  })
  .set(REMINDERS_VEHICLES_CREATE, {
    page: pages.AddReminderVehiclesPage,
    permissions: [],
  })
  .set(`${REMINDERS_VEHICLES_EDIT}/:id`, {
    page: pages.EditReminderVehiclesPage,
    permissions: [],
  })
  .set(`${REMINDERS_SERVICES_CREATE}`, {
    page: pages.AddServiceReminderPage,
    permissions: [],
  })
  .set(`${REMINDERS_SERVICES_UPDATE}/:id`, {
    page: pages.EditServiceReminderPage,
    permissions: [],
  })
  .set(REMINDERS_SERVICES, {
    page: pages.ReminderServiceListPage,
    permissions: [],
  })
  .set(`${REMINDERS_SERVICES_ID(":id")}`, {
    page: pages.ReminderServiceDetailPage,
    permissions: [],
  })
  .set(DASHBOARD, {
    page: pages.Dashboard,
    permissions: [],
  })
  .set(REPORTS, {
    page: pages.Reports,
    permissions: [],
  })
  .set(`${REPORTS}/:id`, {
    page: pages.Reports,
    permissions: [],
  })
  .set(REPORTS_LIST_OF_UNITS, {
    page: pages.ReportsListOfUnits,
    permissions: [],
  })
  .set(REPORTS_STATUS_CHANGES, {
    page: pages.ReportsStatusChanges,
    permissions: [],
  })
  .set(REPORTS_STATUS_SUMMARY, {
    page: pages.ReportsStatusSummary,
    permissions: [],
  })
  .set(TRAINING_VIDEOS, {
    page: pages.TrainingVideoDashboard,
    permissions: [],
  })
  .set(REPORTS_COST_COMPARISON_BY_YEAR, {
    page: pages.ReportsCostComparisonInService,
    permissions: [],
  })
  .set(REPORTS_VEHICLES_RENEWAL_REMINDERS, {
    page: pages.ReportsRenewalReminders,
    permissions: [],
  })
  .set(REPORTS_INSPECTIONS_SUBMISSION_SUMMARY, {
    page: pages.ReportsInspectionsSubmissionSummary,
    permissions: [],
  })
  .set(REPORTS_VEHICLE_ASSIGNMENTS_LOG, {
    page: pages.ReportsAssignmentsLog,
    permissions: [],
  })
  .set(REPORTS_VEHICLE_ASSIGNMENTS_SUMMARY, {
    page: pages.ReportsAssignmentsSummary,
    permissions: [],
  })
  .set(REPORTS_INSPECTIONS_SUBMISSION_LIST, {
    page: pages.ReportsInspectionsSubmissionList,
    permissions: [],
  })
  .set(REPORTS_INSPECTIONS_FAILED_INSPECTIONS, {
    page: pages.ReportsInspectionsFailed,
    permissions: [],
  })
  .set(REPORTS_VEHICLE_DETAILS, {
    page: pages.ReportsVehiclesDetail,
    permissions: [],
  })
  .set(REPORTS_OPERATING_COST_SUMMARY, {
    page: pages.ReportsOperatingCostSummary,
    permissions: [],
  })
  .set(REPORTS_ISSUES_LIST, {
    page: pages.ReportsIssuesList,
    permissions: [],
  })
  .set(REPORTS_USERS_RENEWAL_REMINDERS, {
    page: pages.ReportsUsersRenewalReminders,
    permissions: [],
  })
  .set(REPORTS_ISSUES_FAULTS_SUMMARY, {
    page: pages.ReportsIssueFaultsSummary,
    permissions: [],
  })
  .set(REPORTS_SERVICES_REMINDERS, {
    page: pages.ReportsServiceReminders,
    permissions: [],
  })
  .set(`${REPORTS_VEHICLE_DETAILS_ID(":id")}`, {
    page: pages.ReportsVehiclesDetailId,
    permissions: [],
  })
  .set(REPORTS_WORK_ORDERS_LIST, {
    page: pages.ReportsWorkOrdersListPage,
    permissions: [],
  })
  .set(REPORTS_WORK_ORDERS_BY_VEHICLE, {
    page: pages.ReportsWorkOrdersByVehicle,
    permissions: [],
  })
  .set(REPORTS_USERS_LIST, {
    page: pages.ReportsUsersListPage,
    permissions: [],
  })
  .set(REPORTS_WORK_ORDERS_LABOR_LIST, {
    page: pages.ReportsWorkOrdersLaborListPage,
    permissions: [],
  })
  .set(REPORTS_PARTS_BY_LOCATION, {
    page: pages.ReportsPartsByLocation,
    permissions: [],
  })
  .set(REPORTS_PURCHASE_ORDERS_LIST, {
    page: pages.ReportsPurchaseOrdersList,
    permissions: [],
  })
  .set(REPORTS_SERVICES_WITHOUT_SERVICE, {
    page: pages.ReportsServicesWithoutServices,
    permissions: [],
  })
  .set(REPORTS_SERVICE_REMINDER_COMPLIANCE, {
    page: pages.ReportServiceReminderCompliance,
    permissions: [],
  })
  .set(REPORTS_PART_BY_VEHICLE, {
    page: pages.ReportPartByVehicle,
    permissions: [],
  })
  .set(REPORTS_SERVICE_HISTORY_SUMMARY, {
    page: pages.ReportsServiceHistorySummary,
    permissions: [],
  })
  .set(REPORTS_SERVICE_HISTORY_BY_VEHICLE, {
    page: pages.ReportsServiceHistoryByVehicle,
    permissions: [],
  })
  .set(REPORTS_PARTS_ACTIVITY, {
    page: pages.ReportsPartsActivity,
    permissions: [],
  })
  .set(IMPORT_FILES, {
    page: pages.ImportFilesPage,
    permissions: [],
  })

  .set(REPORTS_FUEL_ENTRIES_BY_VEHICLE, {
    page: pages.ReportFuelEntriesByVehicles,
    permissions: [],
  })
  .set(REPORTS_FUEL_SUMMARY, {
    page: pages.ReportFuelSummary,
    permissions: [],
  })
  .set(REPORTS_FUEL_SUMMARY_BY_LOCATION, {
    page: pages.ReportFuelSummaryByLocation,
    permissions: [],
  })
  .set(EXPENSE, {
    page: pages.ExpenseListPage,
    permissions: [],
  })
  .set(`${EXPENSE}/:id`, {
    page: pages.ExpenseDetailPage,
    permissions: [],
  })
  .set(`${EXPENSE_EDIT}/:id`, {
    page: pages.EditExpensePage,
    permissions: [],
  })
  .set(EXPENSE_CREATE, {
    page: pages.CreateExpensePage,
    permissions: [],
  });
export const routeArray = Array.from(routeMap, ([path, r]) => ({ ...r, path }));
