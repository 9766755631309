import Auth from "Service/api/auth";
import Type from "Service/api/type";
import AspirationType from "./aspiration";
import BlockType from "./blockType";
import BodySubType from "./bodySubType";
import BodyType from "./bodyType";
import BrakeType from "./brakeType";
import CamType from "./camType";
import FuelType from "./fuelTypes";
import GroupType from "./groupType";
import LabelsType from "./labels";
import MakeType from "./make";
import ModelType from "./model";
import TransmissionType from "./transmissionType";
import DriveType from "./drive";
import FuelInductionType from "./IFuelInduction";
import VehicleType from "./vehicle";
import VenderType from "./purchaseVendor";
import Role from "./role";
import Document from "./document";
import User from "./user";
import CompanyProps from "./companyProps";
import Issue from "./issue";
import TelematicDevice from "./telematic-device";
import Samsara from "./samsara-inegration";
import Assignment from "./assignment";
import Faults from "./faults";
import MeterHistory from "./meter-history";
import Part from "./parts";
import PurchaseOrder from "./puchaseOrder";
import Vendors from "./vendors";
import serviceTask from "./serviceTask";
import ServicesHistory from "./service-history";
import WorkOrder from "./workOrder";
import Inspections from "./inspections";
import Equipment from "./equipment";
import FuelHistory from "./fuelHistory";
import Video from "./training";
import ReminderVehicle from "./reminder-vehicle";
import ReminderContact from "./reminder-contact";
import GoogleMap from "./google-map";
import ReminderServices from "./reminder-service";
import Reports from "./reports";
import InputGlobal from "./inputGlobal";
import Dashboard from "./dashboard";
import ImportData from "./importType";
import Invoices from "./invoice";
import Payment from "./payment";
import Expense from "./expense";
import AI from "./ai";

class ApiService {
  public auth = new Auth();
  public vehicle = new VehicleType();
  public type = new Type();
  public bodyType = new BodyType();
  public bodySubType = new BodySubType();
  public fuelType = new FuelType();
  public makeType = new MakeType();
  public modelType = new ModelType();
  public labelsType = new LabelsType();
  public groupType = new GroupType();
  public aspiration = new AspirationType();
  public blockType = new BlockType();
  public camType = new CamType();
  public transmissionTypeList = new TransmissionType();
  public driveType = new DriveType();
  public brakeType = new BrakeType();
  public fuelInduction = new FuelInductionType();
  public vendorstype = new VenderType();
  public role = new Role();
  public document = new Document();
  public user = new User();
  public issue = new Issue();
  public companyProps = new CompanyProps();
  public assignment = new Assignment();
  public telematicDevice = new TelematicDevice();
  public samsara = new Samsara();
  public faults = new Faults();
  public meterHistory = new MeterHistory();
  public part = new Part();
  public purchaseOrder = new PurchaseOrder();
  public vendors = new Vendors();
  public serviceTask = new serviceTask();
  public services_history = new ServicesHistory();
  public workOrder = new WorkOrder();
  public inspections = new Inspections();
  public equipment = new Equipment();
  public fuelHistory = new FuelHistory();
  public traningVideo = new Video();
  public reminderVehicle = new ReminderVehicle();
  public remidnerConctact = new ReminderContact();
  public reminderServices = new ReminderServices();
  public googleMap = new GoogleMap();
  public reports = new Reports();
  public inputGlobal = new InputGlobal();
  public dashboard = new Dashboard();
  public importData = new ImportData();
  public invoice = new Invoices();
  public payment = new Payment();
  public expense = new Expense();
  public ai = new AI();
}

export default new ApiService();
