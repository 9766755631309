import { client } from "Service/axios";
import {
  EXPENSE,
  EXPENSE_DELETE,
  VEHICLES_WITH_EXPENSES,
} from "Shared/Constants/apiRequest";
import {
  PaginatedExpenseListList,
  PaginatedExpenseVehicleList,
} from "Shared/Types/fleetmanagementapi";
import { IExpenseSearchParams } from "Shared/Types/expense";

class Expense {
  getExpenseList(params: IExpenseSearchParams | null) {
    return client.get<PaginatedExpenseListList>(EXPENSE, { params });
  }

  getVehiclesWithExpense(params: IExpenseSearchParams | null) {
    return client.get<PaginatedExpenseVehicleList>(VEHICLES_WITH_EXPENSES, {
      params,
    });
  }

  deleteExpense(id: string) {
    return client.delete<any>(EXPENSE_DELETE(id));
  }
}

export default Expense;
