export const ServiceTasksTableHead = [
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
  { id: "service_entries_count", label: "Service Entries" },
  { id: "work_order_count", label: "Work Orders" },
  { id: "reason_for_repair_code", label: "Default Reason For Repair Code" },
  { id: "system_code", label: "Default System Code" },
  { id: "assembly_code", label: "Default Assembly Code" },
  { id: "meatballs", label: "" },
];
export const getErrorMessage = (
  errorField: any,
  defaultMessage = "Something went wrong",
): string => {
  const errorFields = Array.isArray(errorField) ? errorField : [errorField];

  for (let i = 0; i < errorFields.length; i++) {
    const errorFieldToLowerCase = errorFields[i]?.toLowerCase();

    if (errorFieldToLowerCase === "undefined" || errorFields[i] === undefined) {
      return defaultMessage;
    }
  }

  return errorField || defaultMessage;
};
