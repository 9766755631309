import { client } from "Service/axios";
import { AI_INSPECTION_ANALYZE } from "Shared/Constants/apiRequest";
import { AIRequest, AIResponse } from "Shared/Types/ai";

class AI {
  inspectionImageAnalyze(image: AIRequest) {
    return client.post<AIResponse>(AI_INSPECTION_ANALYZE, image);
  }
}
export default AI;
