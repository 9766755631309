import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { getErrorMessage } from "Shared/Constants/serviceTasks";
import { AIRequest } from "Shared/Types/ai";
import { IReducerWithCallbackWithError } from "Shared/Types/shared";

const generateTextFromImage = createAsyncThunk(
  "ai/generateTextFromImage",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallbackWithError<AIRequest>) => {
    try {
      const result = await api.ai.inspectionImageAnalyze(data);
      onSuccess?.(result);
      return result.data;
    } catch (error: any) {
      const errorMessage = getErrorMessage(error?.response?.data?.error);
      onError?.(errorMessage);
      throw error;
    }
  },
);

export { generateTextFromImage };
